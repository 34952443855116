import React, {Component} from "react";
import {graphql} from "gatsby";
import CssBaseline from "@material-ui/core/CssBaseline/CssBaseline";
import Meta from "../components/Meta";
import BookingSplit from "../components/BookingSplit";
import '../components/layout.scss';
import { store } from '../app/store';
import { Provider } from 'react-redux';

class PageBooking extends Component {
    render() {

        let meta = {
            metaTitle: "Book with us – [name]",
            metaDescription: "The availability checker page in our booking process",
            ogTitle: "Book with us – [name] ",
            ogDescription: "Table availability at our restaurant in [displayLocation]",
            twitterTitle: "Book with us – [name] ",
            twitterDescription: "Table availability at our [displayLocation] pub",
            ogImage: null,
            twitterImage: null,
        }

      return (
          <>
            <Provider store={store}>
              <CssBaseline />
              <style>{"body{max-width:100%;overflow-x:hidden;}img{max-width:100%;}.gatsby-image-wrapper{position:relative;}.gatsby-image-wrapper picture{position:absolute;top:0;right:0;bottom:0;left:0;}.mb-0{margin-bottom: 0 !important;"}</style>
              <Meta meta={meta} title={meta.title} ii={this.props.data.wp.themeOptions.ThemeOptions.individualInnsBranding} />
              <BookingSplit themeOptions={this.props.data.wp.themeOptions} />
            </Provider>
          </>
      )
    }
}

export const query = graphql`
query {
  wp {
    themeOptions {
      accommodationActive
      roomBookingCTAText
      roomBookingImage
      roomBookingImageText
      roomBookingLink
      tableBookingAccountId
      tableBookingActive
      tableBookingBrandId
      tableBookingCTAText
      tableBookingImage
      tableBookingImageText
      tableBookingRestaurantId
      liveResSiteId
      ThemeOptions {
        siteLogo {
          mediaItemUrl
        }
        individualInnsBranding
      }
    }    
  }
}
`;

export default PageBooking
