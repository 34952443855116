import  React from 'react';
import { makeStyles } from '@material-ui/styles';
import Card from "@material-ui/core/Card/Card";
import CardActionArea from "@material-ui/core/CardActionArea/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Button from "@material-ui/core/Button/Button";
import Link from '../Link';
import arrowPrev from '../../images/arrow-prev-light.png';

const useStyles = makeStyles((theme) => ({
    bookingSplit: {
        backgroundColor: theme.palette.secondary.main,
        padding: 22,
        position: 'relative',
        minHeight: '100vh',
        [theme.breakpoints.up('md')]: {
            padding: 60,
        },
        '& div:focus': {
            outline: 'none',
        },
        '& .site-logo': {
            display: 'inline-block',
            marginBottom: 24,
            [theme.breakpoints.up('md')]: {
                marginBottom: 60,
            },
            '& img': {
                maxHeight: 30,
                [theme.breakpoints.up('lg')]: {
                    maxHeight: 38,
                }
            },
        },
        '& .back-link': {
            color: theme.palette.secondary.contrastText,
            fontSize: 16,
            letterSpacing: '0.016em',
            lineHeight: 1.5,
            float: 'right',
            '& .MuiButton-label': {
                '& span': {
                    marginRight: 10,
                    width: 18,
                    height: 18,
                    backgroundImage: 'url(' + arrowPrev + ')',
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    display: 'inline-block',
                    verticalAlign: 'text-top',
                }
            },
        },
        '& .booking-cards': {
            [theme.breakpoints.up('sm')]: {
                display: 'flex',
                flexDirection: 'row',
                maxWidth: 900,
                margin: '0 auto',
            },
            '& .booking-card': {
                marginBottom: 24,
                [theme.breakpoints.up('sm')]: {
                    padding: 12,
                    flex: '0 0 50%',
                    maxWidth: '50%',
                },
                [theme.breakpoints.up('md')]: {
                    '& .MuiCardMedia-root': {
                        height: '470px !important',
                    }
                },
                '& .MuiCardContent-root': {
                    position: 'absolute',
                    backgroundColor: 'rgba(27,20,18,0.35)',
                    color: 'white',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    padding: 30,
                    '& h2': {
                        color: 'white',
                        fontFamilty: theme.headerFont,
                        fontSize: 32,
                        letterSpacing: '0.034em',
                        lineHeight: '1.1',
                        maxWidth: 150,
                        margin: '0 auto 12px',
                        textTransform: 'uppercase',
                        fontWeight: '400',
                        textAlign: 'center',
                        [theme.breakpoints.up('md')]: {
                            padding: '90px 0',
                            fontSize: 53,
                            lineHeight: '0.9',
                        },
                    },
                    '& h3': {
                        width: '220px',
                        display: 'block',
                        borderRadius: 4,
                        fontFamily: theme.headerFont,
                        padding: '12px 22px',
                        boxShadow: '0 1px 3px rgba(0,0,0,0.2)',
                        fontSize: 16,
                        lineHeight: 1.2,
                        fontWeight: 400,
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        zIndex: 999,
                        margin: '0 auto',
                        letterSpacing: '0.06em',
                        color: theme.palette.primary.contrastText,
                        backgroundColor: theme.palette.primary.main,
                        '&:hover, &:focus': {
                            backgroundColor: theme.palette.primary.dark,
                        }
                    }
                }
            }
        },
    },
    bookingSplitTheme: {
        ...theme.bookingSplitTheme,
    }
}));

export default function BookingSplit(props) {

    const classes = useStyles();
    
    return (
        <div className={`${classes.bookingSplit} ${classes.bookingSplitTheme}`}>
            <Button className="back-link" onClick={() => window.history.back()}><span></span> Back</Button>
            <div className="site-logo">
                <Link to="/">
                    <img src={props.themeOptions.ThemeOptions.siteLogo.mediaItemUrl} alt=""/>
                </Link>
            </div>
            <div className="booking-cards">
                {props.themeOptions.accommodationActive==="true" ?
                    <div className="booking-card">
                        <Link to={props.themeOptions.roomBookingLink}>
                            <Card>
                                <CardActionArea>
                                    <CardMedia
                                        image={props.themeOptions.roomBookingImage}
                                        title={props.themeOptions.roomBookingImageText}
                                        style={{height: 205}}
                                    />
                                    <CardContent>
                                        <h2>{props.themeOptions.roomBookingImageText}</h2>
                                        <h3>{props.themeOptions.roomBookingCTAText}</h3>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Link>
                    </div>
                    : '' }

                {props.themeOptions.tableBookingActive==="true" ?
                    <div className="booking-card">
                        <Link to="/booking/book-a-table/">
                            <Card>
                                <CardActionArea>
                                    <CardMedia
                                        image={props.themeOptions.tableBookingImage}
                                        title={props.themeOptions.tableBookingImageText}
                                        style={{height: 205}}
                                    />
                                    <CardContent>
                                        <h2>{props.themeOptions.tableBookingImageText}</h2>
                                        <h3>{props.themeOptions.tableBookingCTAText}</h3>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Link>
                    </div>
                    : '' }
            </div>
        </div>
    )
};